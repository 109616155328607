/* eslint-disable consistent-return */
import axios from 'axios';
import {
  CloudinaryUploadPreset,
  CloudinaryAPIKey,
  CloudinaryImageUploadUrl,
  CloudinaryVideoUploadUrl,
} from 'Common/globals';
import ReactOnRails from 'react-on-rails';
import * as errorTracker from 'Utils/errorTracker';

const ApiUtils = {
  // Upload image/video api
  uploadFile(image, isVideo) {
    try {
      const url = isVideo ? CloudinaryVideoUploadUrl : CloudinaryImageUploadUrl;
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', CloudinaryUploadPreset);
      formData.append('api_key', CloudinaryAPIKey);
      // eslint-disable-next-line no-bitwise
      formData.append('timestamp', (Date.now() / 1000) | 0);

      return axios.post(url, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': undefined },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // get all users list for admin api
  getAllUsers(pageNo, search) {
    try {
      return axios.get('/users', {
        params: {
          timestamp: new Date().getTime(),
          page: pageNo,
          q: search,
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // get all Leads list for admin api
  getAllLeads(pageNo, search, sortType, sortBy) {
    let url = `?timestamp=${new Date().getTime()}&page=${pageNo}`;
    if ((search && search.length > 0 && !sortBy) || !sortType) {
      url = `${url}&q=${search}`;
    }
    if (search && sortBy && sortType) {
      url = `${url}&q=${search}&sort_by=${sortBy}&sort_type=${sortType}`;
    }
    if (!search && sortBy && sortType) {
      url = `${url}&sort_by=${sortBy}&sort_type=${sortType}`;
    }
    try {
      return axios.get(`/all-leads${url}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },
  // update user api
  updateUser(userId, form) {
    try {
      return axios.patch(`/users/${userId}`, form, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'X-CSRF-Token': ReactOnRails.authenticityToken(),
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // resend confirmation api
  resendConfirm(csrfToken, form) {
    try {
      return axios.post('/users/confirmation', form, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // user sign out api
  userSignOut(csrfToken) {
    try {
      return axios.delete('/users/sign_out', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // get city from country api
  getCountryLocations(userId, country, city, provider, speciality, csrfToken, page) {
    try {
      return axios.get(`/locations/country/${country}`, {
        params: {
          user_id: userId,
          page,
          city,
          provider,
          speciality,
          timestamp: new Date().getTime(),
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      });
    } catch (error) {
      errorTracker.captureException(error);
    }
  },

  // get isUserSignedIn
  getIsUserSignedIn() {
    try {
      return axios.get(`/is_user_sign_in`);
    } catch (error) {
      errorTracker.captureException(error);
    }
  },
};

export default ApiUtils;
