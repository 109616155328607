import 'Utils/errorTracker';
import ReactOnRails from 'react-on-rails';
import AppStore from 'Bundles/stores/clientStore';

import {
  About,
  AdminOrders,
  BlogCategory,
  BlogPostNew,
  BlogPostEdit,
  BlogPostShow,
  BlogPostsIndex,
  BlogPostsDashboard,
  BlogPostsDashboardAnalytics,
  BlogPostCategoriesDashboard,
  BlogPostCategoryNew,
  BlogPostCategoryEdit,
  CartReservationComplete,
  UserChangePassword,
  Contact,
  CreateBareMetalProjectConfiguration,
  CreateBareMetalProjectProvidersSelection,
  CreateBareMetalProjectGeneralInformation,
  CreateCloudProjectCloudTypeSelection,
  CreateCloudProjectGeneralInformation,
  CreateCloudProjectIaasCloudServersConfiguration,
  CreateCloudProjectIaasCloudStorageConfiguration,
  CreateCloudProjectProvidersSelection,
  CreateColocationRfpProjectConfiguration,
  CreateColocationRfpProjectGeneralInformation,
  CreateColocationRfpProjectLocations,
  CreateInternetRfpProjectConfiguration,
  CreateInternetRfpProjectGeneralInformation,
  CreateInternetRfpProjectProvidersSelection,
  CreateNetworkProjectGeneralInformation,
  CreateNetworkProjectMplsNetworkConfiguration,
  CreateNetworkProjectNetworkTypeSelection,
  CreateNetworkProjectPrivateLineConfiguration,
  CreateNetworkProjectProvidersSelection,
  CreateNetworkProjectSdWanNetworkConfiguration,
  DashboardCreateProduct,
  DashboardEditProductAssetsTab,
  DashboardEditProductGeneralTab,
  DashboardEditProductLocationTab,
  DashboardProducts,
  DashboardCreateProvider,
  DetailsSidebar,
  Footer,
  ForgotPassword,
  HomeIndex,
  InviteSignUp,
  LeadConfirmed,
  LeadConfirmedColocation,
  LeadConfirmedBareMetal,
  LeadConfirmedZenlayerBareMetal,
  LeadConfirmedEnzuBareMetal,
  LeadConfirmedHivelocityBareMetal,
  LeadConfirmedEvocativeBareMetal,
  LeadConfirmedGeneral,
  LeadConfirmedMulticloud,
  LocationsIndex,
  LocationShow,
  LocationsDashboard,
  LocationBrochuresDashboard,
  LocationBrochuresIndex,
  LocationBrochureShow,
  LocationBrochureNew,
  LocationBrochureEdit,
  MarketplaceIndex,
  ModalHeader,
  Navigation,
  PrivacyPolicy,
  ProductCreateReservation,
  ProductDescription,
  ProductGrid,
  ProductReservationComplete,
  ProductSpecs,
  ProductTile,
  ProductView,
  ProviderShow,
  ProviderEdit,
  ProviderLocationsIndex,
  ProviderProjectProposalForm,
  ProvidersList,
  ProvidersDashboard,
  ProviderBrochuresDashboard,
  ProviderBrochureNew,
  ProviderBrochureEdit,
  ProviderBrochureShow,
  ProviderBrochuresIndex,
  ResendConfirmation,
  ResourceDetail,
  ResourceLanding,
  ResourceProviders,
  ResourcesDashboard,
  ResourcesAnalytics,
  ResourceCategoriesDashboard,
  ResourceNew,
  ResourceEdit,
  SignIn,
  SignUp,
  Sitemap,
  Terms,
  ThanksForContact,
  ThanksForDownload,
  ThanksForSignup,
  ThanksForSignupProvider,
  UserCloudIaasServerProject,
  UserCloudIaasStorageProject,
  UserColocationRfpProject,
  UserEditProfile,
  UserInternetRfpProject,
  UserBareMetalProject,
  UserInvitations,
  UsersDashboard,
  UserListCloudRack,
  UserNetworkMplsProject,
  UserNetworkPrivateLineProject,
  UserNetworkSdWanProject,
  UserOrders,
  UserProjectProposal,
  UserProjectProposals,
  UserProjectProviders,
  UserProjects,
  UserCloudRack,
  UserDashboardEdit,
  UserDashboardNotifications,
  Multicloud,
  Colocation,
  BareMetalIndex,
  ZenlayerBareMetal,
  EnzuBareMetal,
  HivelocityBareMetal,
  EvocativeBareMetal,
  CountriesIndex,
  CountryShow,
  StatesIndex,
  StateShow,
  CitiesIndex,
  CityShow,
  CountriesDashboard,
  CountryEdit,
  StatesDashboard,
  StateEdit,
  CitiesDashboard,
  CityEdit,
  ProviderCountryShow,
  ProviderStateShow,
  ProviderCityShow,
  SessionUserUnlock,
  SuspiciousEmailEdit,
  DashboardSuspiciousEmailsIndex,
  SuspiciousIpEdit,
  DashboardSuspiciousIpsIndex,
} from 'Loadable/components.imports-loadable.jsx';

import '../global';
import * as ReactGA from '../rescript/bindings/ReactGA.res.js';
import ClientAppHOC from '../loadable/ClientAppHOC.imports-loadable';

ReactOnRails.registerStore({
  AppStore,
});

ReactOnRails.register({
  About: ClientAppHOC(About, 'About'),
  AdminOrders: ClientAppHOC(AdminOrders, 'AdminOrders'),
  BlogCategory: ClientAppHOC(BlogCategory, 'BlogCategory'),
  BlogPostNew: ClientAppHOC(BlogPostNew, 'BlogPostNew'),
  BlogPostEdit: ClientAppHOC(BlogPostEdit, 'BlogPostEdit'),
  BlogPostShow: ClientAppHOC(BlogPostShow, 'BlogPostShow'),
  BlogPostsIndex: ClientAppHOC(BlogPostsIndex, 'BlogPostsIndex'),
  BlogPostsDashboard: ClientAppHOC(BlogPostsDashboard, 'BlogPostsDashboard'),
  BlogPostsDashboardAnalytics: ClientAppHOC(BlogPostsDashboardAnalytics, 'BlogPostsDashboardAnalytics'),
  BlogPostCategoriesDashboard: ClientAppHOC(BlogPostCategoriesDashboard, 'BlogPostCategoriesDashboard'),
  BlogPostCategoryNew: ClientAppHOC(BlogPostCategoryNew, 'BlogPostCategoryNew'),
  BlogPostCategoryEdit: ClientAppHOC(BlogPostCategoryEdit, 'BlogPostCategoryEdit'),
  CartReservationComplete: ClientAppHOC(CartReservationComplete, 'CartReservationComplete'),
  UserChangePassword: ClientAppHOC(UserChangePassword, 'UserChangePassword'),
  Contact: ClientAppHOC(Contact, 'Contact'),
  CreateBareMetalProjectConfiguration: ClientAppHOC(
    CreateBareMetalProjectConfiguration,
    'CreateBareMetalProjectConfiguration',
  ),
  CreateBareMetalProjectProvidersSelection: ClientAppHOC(
    CreateBareMetalProjectProvidersSelection,
    'CreateBareMetalProjectProvidersSelection',
  ),
  CreateBareMetalProjectGeneralInformation: ClientAppHOC(
    CreateBareMetalProjectGeneralInformation,
    'CreateBareMetalProjectGeneralInformation',
  ),
  CreateCloudProjectCloudTypeSelection: ClientAppHOC(
    CreateCloudProjectCloudTypeSelection,
    'CreateCloudProjectCloudTypeSelection',
  ),
  CreateCloudProjectGeneralInformation: ClientAppHOC(
    CreateCloudProjectGeneralInformation,
    'CreateCloudProjectGeneralInformation',
  ),
  CreateCloudProjectIaasCloudServersConfiguration: ClientAppHOC(
    CreateCloudProjectIaasCloudServersConfiguration,
    'CreateCloudProjectIaasCloudServersConfiguration',
  ),
  CreateCloudProjectIaasCloudStorageConfiguration: ClientAppHOC(
    CreateCloudProjectIaasCloudStorageConfiguration,
    'CreateCloudProjectIaasCloudStorageConfiguration',
  ),
  CreateCloudProjectProvidersSelection: ClientAppHOC(
    CreateCloudProjectProvidersSelection,
    'CreateCloudProjectProvidersSelection',
  ),
  CreateColocationRfpProjectConfiguration: ClientAppHOC(
    CreateColocationRfpProjectConfiguration,
    'CreateColocationRfpProjectConfiguration',
  ),
  CreateColocationRfpProjectGeneralInformation: ClientAppHOC(
    CreateColocationRfpProjectGeneralInformation,
    'CreateColocationRfpProjectGeneralInformation',
  ),
  CreateColocationRfpProjectLocations: ClientAppHOC(
    CreateColocationRfpProjectLocations,
    'CreateColocationRfpProjectLocations',
  ),
  CreateInternetRfpProjectConfiguration: ClientAppHOC(
    CreateInternetRfpProjectConfiguration,
    'CreateInternetRfpProjectConfiguration',
  ),
  CreateInternetRfpProjectGeneralInformation: ClientAppHOC(
    CreateInternetRfpProjectGeneralInformation,
    'CreateInternetRfpProjectGeneralInformation',
  ),
  CreateInternetRfpProjectProvidersSelection: ClientAppHOC(
    CreateInternetRfpProjectProvidersSelection,
    'CreateInternetRfpProjectProvidersSelection',
  ),
  CreateNetworkProjectGeneralInformation: ClientAppHOC(
    CreateNetworkProjectGeneralInformation,
    'CreateNetworkProjectGeneralInformation',
  ),
  CreateNetworkProjectMplsNetworkConfiguration: ClientAppHOC(
    CreateNetworkProjectMplsNetworkConfiguration,
    'CreateNetworkProjectMplsNetworkConfiguration',
  ),
  CreateNetworkProjectNetworkTypeSelection: ClientAppHOC(
    CreateNetworkProjectNetworkTypeSelection,
    'CreateNetworkProjectNetworkTypeSelection',
  ),
  CreateNetworkProjectPrivateLineConfiguration: ClientAppHOC(
    CreateNetworkProjectPrivateLineConfiguration,
    'CreateNetworkProjectPrivateLineConfiguration',
  ),
  CreateNetworkProjectProvidersSelection: ClientAppHOC(
    CreateNetworkProjectProvidersSelection,
    'CreateNetworkProjectProvidersSelection',
  ),
  CreateNetworkProjectSdWanNetworkConfiguration: ClientAppHOC(
    CreateNetworkProjectSdWanNetworkConfiguration,
    'CreateNetworkProjectSdWanNetworkConfiguration',
  ),
  DashboardCreateProduct: ClientAppHOC(DashboardCreateProduct, 'DashboardCreateProduct'),
  DashboardEditProductAssetsTab: ClientAppHOC(DashboardEditProductAssetsTab, 'DashboardEditProductAssetsTab'),
  DashboardEditProductGeneralTab: ClientAppHOC(
    DashboardEditProductGeneralTab,
    'DashboardEditProductGeneralTab',
  ),
  DashboardEditProductLocationTab: ClientAppHOC(
    DashboardEditProductLocationTab,
    'DashboardEditProductLocationTab',
  ),
  DashboardProducts: ClientAppHOC(DashboardProducts, 'DashboardProducts'),
  DashboardCreateProvider: ClientAppHOC(DashboardCreateProvider, 'DashboardCreateProvider'),
  DetailsSidebar: ClientAppHOC(DetailsSidebar, 'DetailsSidebar'),
  Footer: ClientAppHOC(Footer, 'Footer'),
  ForgotPassword: ClientAppHOC(ForgotPassword, 'ForgotPassword'),
  HomeIndex: ClientAppHOC(HomeIndex, 'HomeIndex'),
  InviteSignUp: ClientAppHOC(InviteSignUp, 'InviteSignUp'),
  LeadConfirmed: ClientAppHOC(LeadConfirmed, 'LeadConfirmed'),
  LeadConfirmedBareMetal: ClientAppHOC(LeadConfirmedBareMetal, 'LeadConfirmedBareMetal'),
  LeadConfirmedZenlayerBareMetal: ClientAppHOC(
    LeadConfirmedZenlayerBareMetal,
    'LeadConfirmedZenlayerBareMetal',
  ),
  LeadConfirmedEnzuBareMetal: ClientAppHOC(LeadConfirmedEnzuBareMetal, 'LeadConfirmedEnzuBareMetal'),
  LeadConfirmedHivelocityBareMetal: ClientAppHOC(
    LeadConfirmedHivelocityBareMetal,
    'LeadConfirmedHivelocityBareMetal',
  ),
  LeadConfirmedEvocativeBareMetal: ClientAppHOC(
    LeadConfirmedEvocativeBareMetal,
    'LeadConfirmedEvocativeBareMetal',
  ),
  LeadConfirmedColocation: ClientAppHOC(LeadConfirmedColocation, 'LeadConfirmedColocation'),
  LeadConfirmedGeneral: ClientAppHOC(LeadConfirmedGeneral, 'LeadConfirmedGeneral'),
  LeadConfirmedMulticloud: ClientAppHOC(LeadConfirmedMulticloud, 'LeadConfirmedMulticloud'),
  LocationsIndex: ClientAppHOC(LocationsIndex, 'LocationsIndex'),
  LocationShow: ClientAppHOC(LocationShow, 'LocationShow'),
  LocationsDashboard: ClientAppHOC(LocationsDashboard, 'LocationsDashboard'),
  LocationBrochuresDashboard: ClientAppHOC(LocationBrochuresDashboard, 'LocationBrochuresDashboard'),
  LocationBrochuresIndex: ClientAppHOC(LocationBrochuresIndex, 'LocationBrochuresIndex'),
  LocationBrochureShow: ClientAppHOC(LocationBrochureShow, 'LocationBrochureShow'),
  LocationBrochureNew: ClientAppHOC(LocationBrochureNew, 'LocationBrochureNew'),
  LocationBrochureEdit: ClientAppHOC(LocationBrochureEdit, 'LocationBrochureEdit'),
  MarketplaceIndex: ClientAppHOC(MarketplaceIndex, 'MarketplaceIndex'),
  ModalHeader: ClientAppHOC(ModalHeader, 'ModalHeader'),
  Navigation: ClientAppHOC(Navigation, 'Navigation'),
  PrivacyPolicy: ClientAppHOC(PrivacyPolicy, 'PrivacyPolicy'),
  ProductCreateReservation: ClientAppHOC(ProductCreateReservation, 'ProductCreateReservation'),
  ProductDescription: ClientAppHOC(ProductDescription, 'ProductDescription'),
  ProductGrid: ClientAppHOC(ProductGrid, 'ProductGrid'),
  ProductReservationComplete: ClientAppHOC(ProductReservationComplete, 'ProductReservationComplete'),
  ProductSpecs: ClientAppHOC(ProductSpecs, 'ProductSpecs'),
  ProductTile: ClientAppHOC(ProductTile, 'ProductTile'),
  ProductView: ClientAppHOC(ProductView, 'ProductView'),
  ProviderShow: ClientAppHOC(ProviderShow, 'ProviderShow'),
  ProviderEdit: ClientAppHOC(ProviderEdit, 'ProviderEdit'),
  ProviderLocationsIndex: ClientAppHOC(ProviderLocationsIndex, 'ProviderLocationsIndex'),
  ProviderProjectProposalForm: ClientAppHOC(ProviderProjectProposalForm, 'ProviderProjectProposalForm'),
  ProvidersList: ClientAppHOC(ProvidersList, 'ProvidersList'),
  ProvidersDashboard: ClientAppHOC(ProvidersDashboard, 'ProvidersDashboard'),
  ProviderBrochuresDashboard: ClientAppHOC(ProviderBrochuresDashboard, 'ProviderBrochuresDashboard'),
  ProviderBrochureNew: ClientAppHOC(ProviderBrochureNew, 'ProviderBrochureNew'),
  ProviderBrochureEdit: ClientAppHOC(ProviderBrochureEdit, 'ProviderBrochureEdit'),
  ProviderBrochureShow: ClientAppHOC(ProviderBrochureShow, 'ProviderBrochureShow'),
  ProviderBrochuresIndex: ClientAppHOC(ProviderBrochuresIndex, 'ProviderBrochuresIndex'),
  ResendConfirmation: ClientAppHOC(ResendConfirmation, 'ResendConfirmation'),
  ResourceDetail: ClientAppHOC(ResourceDetail, 'ResourceDetail'),
  ResourceLanding: ClientAppHOC(ResourceLanding, 'ResourceLanding'),
  ResourceProviders: ClientAppHOC(ResourceProviders, 'ResourceProviders'),
  ResourcesDashboard: ClientAppHOC(ResourcesDashboard, 'ResourcesDashboard'),
  ResourcesAnalytics: ClientAppHOC(ResourcesAnalytics, 'ResourcesAnalytics'),
  ResourceCategoriesDashboard: ClientAppHOC(ResourceCategoriesDashboard, 'ResourceCategoriesDashboard'),
  ResourceNew: ClientAppHOC(ResourceNew, 'ResourceNew'),
  ResourceEdit: ClientAppHOC(ResourceEdit, 'ResourceEdit'),
  SignIn: ClientAppHOC(SignIn, 'SignIn'),
  SignUp: ClientAppHOC(SignUp, 'SignUp'),
  Sitemap: ClientAppHOC(Sitemap, 'Sitemap'),
  Terms: ClientAppHOC(Terms, 'Terms'),
  ThanksForContact: ClientAppHOC(ThanksForContact, 'ThanksForContact'),
  ThanksForDownload: ClientAppHOC(ThanksForDownload, 'ThanksForDownload'),
  ThanksForSignup: ClientAppHOC(ThanksForSignup, 'ThanksForSignup'),
  ThanksForSignupProvider: ClientAppHOC(ThanksForSignupProvider, 'ThanksForSignupProvider'),
  UserCloudIaasServerProject: ClientAppHOC(UserCloudIaasServerProject, 'UserCloudIaasServerProject'),
  UserCloudIaasStorageProject: ClientAppHOC(UserCloudIaasStorageProject, 'UserCloudIaasStorageProject'),
  UserColocationRfpProject: ClientAppHOC(UserColocationRfpProject, 'UserColocationRfpProject'),
  UserInternetRfpProject: ClientAppHOC(UserInternetRfpProject, 'UserInternetRfpProject'),
  UserBareMetalProject: ClientAppHOC(UserBareMetalProject, 'UserBareMetalProject'),
  UserEditProfile: ClientAppHOC(UserEditProfile, 'UserEditProfile'),
  UserInvitations: ClientAppHOC(UserInvitations, 'UserInvitations'),
  UsersDashboard: ClientAppHOC(UsersDashboard, 'UsersDashboard'),
  UserListCloudRack: ClientAppHOC(UserListCloudRack, 'UserListCloudRack'),
  UserNetworkMplsProject: ClientAppHOC(UserNetworkMplsProject, 'UserNetworkMplsProject'),
  UserNetworkPrivateLineProject: ClientAppHOC(UserNetworkPrivateLineProject, 'UserNetworkPrivateLineProject'),
  UserNetworkSdWanProject: ClientAppHOC(UserNetworkSdWanProject, 'UserNetworkSdWanProject'),
  UserOrders: ClientAppHOC(UserOrders, 'UserOrders'),
  UserProjectProposal: ClientAppHOC(UserProjectProposal, 'UserProjectProposal'),
  UserProjectProposals: ClientAppHOC(UserProjectProposals, 'UserProjectProposals'),
  UserProjectProviders: ClientAppHOC(UserProjectProviders, 'UserProjectProviders'),
  UserProjects: ClientAppHOC(UserProjects, 'UserProjects'),
  UserCloudRack: ClientAppHOC(UserCloudRack, 'UserCloudRack'),
  UserDashboardEdit: ClientAppHOC(UserDashboardEdit, 'UserDashboardEdit'),
  UserDashboardNotifications: ClientAppHOC(UserDashboardNotifications, 'UserDashboardNotifications'),
  Multicloud: ClientAppHOC(Multicloud, 'Multicloud'),
  Colocation: ClientAppHOC(Colocation, 'Colocation'),
  BareMetalIndex: ClientAppHOC(BareMetalIndex, 'BareMetalIndex'),
  ZenlayerBareMetal: ClientAppHOC(ZenlayerBareMetal, 'ZenlayerBareMetal'),
  EnzuBareMetal: ClientAppHOC(EnzuBareMetal, 'EnzuBareMetal'),
  HivelocityBareMetal: ClientAppHOC(HivelocityBareMetal, 'HivelocityBareMetal'),
  EvocativeBareMetal: ClientAppHOC(EvocativeBareMetal, 'EvocativeBareMetal'),
  CountriesIndex: ClientAppHOC(CountriesIndex, 'CountriesIndex'),
  CountryShow: ClientAppHOC(CountryShow, 'CountryShow'),
  StatesIndex: ClientAppHOC(StatesIndex, 'StatesIndex'),
  StateShow: ClientAppHOC(StateShow, 'StateShow'),
  CitiesIndex: ClientAppHOC(CitiesIndex, 'CitiesIndex'),
  CityShow: ClientAppHOC(CityShow, 'CityShow'),
  CountriesDashboard: ClientAppHOC(CountriesDashboard, 'CountriesDashboard'),
  CountryEdit: ClientAppHOC(CountryEdit, 'CountryEdit'),
  StatesDashboard: ClientAppHOC(StatesDashboard, 'StatesDashboard'),
  StateEdit: ClientAppHOC(StateEdit, 'StateEdit'),
  CitiesDashboard: ClientAppHOC(CitiesDashboard, 'CitiesDashboard'),
  CityEdit: ClientAppHOC(CityEdit, 'CityEdit'),
  ProviderCountryShow: ClientAppHOC(ProviderCountryShow, 'ProviderCountryShow'),
  ProviderStateShow: ClientAppHOC(ProviderStateShow, 'ProviderStateShow'),
  ProviderCityShow: ClientAppHOC(ProviderCityShow, 'ProviderCityShow'),
  SessionUserUnlock: ClientAppHOC(SessionUserUnlock, 'SessionUserUnlock'),
  SuspiciousEmailEdit: ClientAppHOC(SuspiciousEmailEdit, 'SuspiciousEmailEdit'),
  DashboardSuspiciousEmailsIndex: ClientAppHOC(
    DashboardSuspiciousEmailsIndex,
    'DashboardSuspiciousEmailsIndex',
  ),
  SuspiciousIpEdit: ClientAppHOC(SuspiciousIpEdit, 'SuspiciousIpEdit'),
  DashboardSuspiciousIpsIndex: ClientAppHOC(DashboardSuspiciousIpsIndex, 'DashboardSuspiciousIpsIndex'),
});

ReactGA.init();
